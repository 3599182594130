<template>
  <div>
    <div>
      <!-- TVA SECTION -->
      <b-table
        selectable
        @row-selected="onRowSelected"
        bordered
        responsive
        :fields="fields"
        :items="billableTasks"
      >
        <template #cell(id)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
          </template>
        </template>
        <template #cell(price)="data">
          <b-form-input
            type="number"
            placeholder="..."
            v-model="data.item.price"
          />
        </template>
      </b-table>

      <div class="d-flex flex-row-reverse">
        <table class="table-bordered table col-4">
          <tbody>
            <tr>
              <td style="background-color: rgba(0, 0, 0, 0.05); width: 20%">
                <strong>TOTAL HT (€)</strong>
              </td>
              <td style="width: 22%">{{ invoiceTotal() }}</td>
            </tr>
            <tr>
              <td style="background-color: rgba(0, 0, 0, 0.05); width: 20%">
                <strong>TVA (€)</strong>
              </td>
              <td style="width: 22%">{{ totalTVA() }}</td>
            </tr>
            <tr>
              <td style="background-color: rgba(0, 0, 0, 0.05); width: 20%">
                <strong>Remise (€)</strong>
              </td>
              <td style="width: 22%">{{ totalDiscount() }}</td>
            </tr>
            <tr>
              <td style="background-color: rgba(0, 0, 0, 0.05)">
                <strong>TOTAL TTC (€)</strong>
              </td>
              <td>{{ invoiceTTC() }}</td>
            </tr>
            
            <tr v-if="advancePaymentsTotal() !== 0">
              <td style="background-color: rgba(0, 0, 0, 0.05)">
                <strong>Total acompte (€)</strong>
              </td>
              <td>{{ advancePaymentsTotal() }}</td>
            </tr>
            
            <tr v-if="finalAmount() !== 0">
              <td style="background-color: rgba(0, 0, 0, 0.05)">
                <strong>Net à payer (€)</strong>
              </td>
              <td>{{ finalAmount() }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center">
        <b-button
          :disabled="thisSelected.length == 0"
          @click="removeDown"
          class="btn-sm mr-2"
        >
          <feather-icon icon="CornerRightDownIcon" size="15"
        /></b-button>
        <b-button
          :disabled="thisSelected.length == 0"
          @click="updateTable"
          class="btn-sm"
        >
          <feather-icon icon="CornerRightUpIcon" size="15"
        /></b-button>
      </div>
    </div>
    <div>
      <b-row>
        <b-col cols="6">
          <b-form>
            Filtrer par tâches :
            <b-row>
              <b-col cols="auto"
                ><b-form-radio v-model="filterData.who_tasks" value="closed"
                  >terminées</b-form-radio
                >
              </b-col>
              <b-col>
                <b-form-radio v-model="filterData.who_tasks" value="inprogress"
                  >en cours</b-form-radio
                ></b-col
              >
            </b-row>
          </b-form>
        </b-col>
        <b-col cols="6" class="my-1">
          <validation-observer ref="searchForm">
            <b-form-group
              label=""
              label-for="filter-input"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Nom de la tâche"
                ></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="tasksFilter(filter)"
                    >Rechercher</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </validation-observer>
        </b-col>
      </b-row>
      <div class="table-responsive" style="height: 450px">
        <div @contextmenu.prevent="$refs.menu.open">
          <b-table
            :key="allTasksTable"
            :no-border-collapse="true"
            responsive
            selectable
            @row-selected="onRowSelected"
            bordered
            :fields="fields2"
            :items="tasksWithoutCustomer"
            selected-variant="primary"
            empty-text="Aucune tâche trouvée"
            show-empty
            class="position-relative"
          >
            <template #cell(id)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
              </template>
            </template>
            <template #cell(is_closed)="data">
              <b-badge v-if="data.item.is_closed == 1" variant="success"
                >Clôturée</b-badge
              >
              <b-badge v-if="data.item.is_closed == 0" variant="warning"
                >Non clôturée</b-badge
              >
            </template>
          </b-table>
        </div>
      </div>
      <vue-context ref="menu">
        <li>
          <b-link @click="onClick()" class="d-flex align-items-center">
            <edit-icon size="1x" class="custom-class"></edit-icon>
            <span class="ml-75" v-b-toggle.sidebar-update
              >Modifier la tâche</span
            >
          </b-link>
        </li>
      </vue-context>

      <div>
        <b-sidebar
          id="sidebar-update"
          ref="mySideBar"
          bg-variant="white"
          width="30%"
          right
          shadow
        >
          <b-card>
            <h4>
              <feather-icon icon="UserPlusIcon" size="18" />
              Modifier votre tâche
            </h4>
            <hr />
            <validation-observer ref="taskUpdateForm">
              <b-form>
                <ky-input
                  :rules="'required'"
                  v-model="tasks.name"
                  :type="'text'"
                  :placeholder="'Nom de la tâche'"
                />

                <ky-input
                  :rules="'required'"
                  v-model="tasks.responsable"
                  :type="'text'"
                  :placeholder="'Responsable'"
                />
                <ky-input
                  :rules="'required'"
                  v-model="tasks.supervisor"
                  :type="'text'"
                  :placeholder="'superviseur'"
                />
                <ky-input
                  :rules="'required'"
                  v-model="tasks.endDate"
                  :type="'date'"
                  :placeholder="'date de fin'"
                />
                <ky-input
                  :rules="'required'"
                  v-model="tasks.project"
                  :type="'text'"
                  :placeholder="'projet'"
                />
                <ky-input
                  :rules="'required'"
                  v-model="tasks.client"
                  :type="'text'"
                  :placeholder="'client'"
                />
                <div class="text-center">
                  <b-row>
                    <b-col>
                      <b-button>Mettre a jour</b-button>
                    </b-col>
                  </b-row>
                </div>
              </b-form>
            </validation-observer>
          </b-card>
        </b-sidebar>
      </div>
    </div>
  </div>
</template>

<script>
import VueContext from "vue-context";
import { mapActions, mapState } from "vuex";
import { EditIcon } from "vue-feather-icons";
import kyInlineInput from "@/@core/layouts/components/ky-inline-input.vue";
import KyInput from "@/@core/layouts/components/ky-input.vue";
import Multiselect from "vue-multiselect";
export default {
  components: {
    VueContext,
    EditIcon,
    kyInlineInput,
    Multiselect,
    KyInput,
  },
  data() {
    return {
      updateTask: false,
      allTasksTable: 0,
      perPageSize: 10,
      filterData: {
        table_size: 10,
        who_tasks: "closed",
        page: 1,
      },
      currentPage: 1,
      taskSelected: "close",
      filter: "",
      thisSelected: [],
      stickyHeader: true,
      price: 0,
      fields: [
        {
          key: "id",
          sortable: true,
          label: "#",
          thStyle: { width: "50px" },
        },
        {
          key: "name",
          label: "Nom",
          sortable: false,
        },
        {
          key: "project",
          sortable: false,
        },
        {
          key: "price",
          sortable: false,
          label: "Prix HT (€)",
          thStyle: { width: "200px" },
        },
      ],
      fields2: [
        {
          key: "id",
          sortable: true,
          label: "#",
          thStyle: { width: "50px" },
        },
        {
          key: "name",
          label: "Nom",
          sortable: false,
        },
        {
          key: "project",
          sortable: false,
        },
        {
          key: "is_closed",
          sortable: false,
          label: "Status",
          thStyle: { width: "150px" },
        },

        {
          key: "master",
          label: "Responsable",
          sortable: true,
          thStyle: { width: "200px" },
        },
      ],
      tasks: {
        id: "",
        name: "",
        responsable: "",
        supervisor: "",
        endDate: "",
        project: "",
        client: "",
      },
    };
  },
  props: {
    customer: { type: Object, required: true },
    invoiceTotal: Function,
    invoiceTTC: Function,
    totalTVA: Function,
    totalDiscount: Function,
    advancePaymentsTotal: {type: Function, required: false},
    finalAmount: Function,
  },

  watch: {
    filter() {
      if (this.filter == "") {
        this.filterData.who_tasks = "closed";
        return this.tasksWithoutCustomerFilter(this.filterData);
      }
    },

    currentPage(val) {
      this.filterData.page = val;
      this.tasksWithoutCustomerFilter(this.filterData);
    },

    perPageSize() {
      this.filterData.table_size = this.perPageSize;
      this.tasksWithoutCustomerFilter(this.filterData.table_size);
    },

    filterData: {
      deep: true,
      handler() {
        this.filterData.table_size = this.perPageSize;
        return this.tasksWithoutCustomerFilter(this.filterData);
      },
    },

    customer() {
      this.allBillableTasks(this.customer.id);
    },
  },
  mounted() {
    this.tasksWithoutCustomerFilter(this.filterData);
  },

  computed: {
    allTasksMeta() {
      return this.tasksWithoutCustomer.meta || {};
    },

    ...mapState("tasks", ["billableTasks", "tasksWithoutCustomer"]),
  },

  methods: {
    ...mapActions("tasks", [
      "allBillableTasks",
      "tasksWithoutCustomerFilter",
      "allBillableTasksFilter",
    ]),

    tasksFilter(data) {
      this.filterData.who_tasks = null;
      this.allBillableTasksFilter(data);
    },

    onRowSelected(billableTasks) {
      this.thisSelected = billableTasks;
    },
    updateTable() {
      var pos = 0;
      for (let index = this.thisSelected.length - 1; index >= 0; index--) {
        pos = this.tasksWithoutCustomer.indexOf(this.thisSelected[index], 0);
        this.tasksWithoutCustomer.splice(pos, 1);
      }
      this.billableTasks.unshift(...this.thisSelected);
    },
    removeDown() {
      var pos = 0;
      for (let index = this.thisSelected.length - 1; index >= 0; index--) {
        pos = this.billableTasks.indexOf(this.thisSelected[index], 0);
        this.billableTasks.splice(pos, 1);
      }
      this.tasksWithoutCustomer.unshift(...this.thisSelected);
    },
    onClick() {
      this.updateTask = true;
    },
  },
};
</script>